import { Injectable, inject } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthService, PROJECT_ENV_CONFIG_TOKEN, StorageService } from 'bp-angular-library';
import { ALL_ROUTES_FOR_1X2TEAM, SIDEMENU_NAV_GROUPS_FOR_1X2TEAM } from './1x2team/routes.1x2team';
import { ALL_ROUTES_FOR_BETPLATFORM, SIDEMENU_NAV_GROUPS_FOR_BETPLATFORM } from './betplatform/routes.betplatform';
import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { Adapter1x2teamService } from './1x2team/adapter-1x2team.service';
import { AdapterBetPlatformService } from './betplatform/adapter-betplatform.service';

import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ILoginCredentials } from 'bp-framework/dist/auth/auth.interface';

import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'bp-framework/dist/configuration/configuration.interface';
import { ITransactionDetails } from 'bp-framework/dist/transactions/transactions.interface';
import { BP_BACKOFFICE_STORAGE_KEYS } from 'src/app/shared/models/storage/storage.const';
import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { IJackpotDetailsWhenAgent } from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';

@Injectable({
  providedIn: 'root'
})
export class EnvAdapterService {
  private projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  constructor(
    private adapter1x2teamService: Adapter1x2teamService,
    private adapterBetPlatformService: AdapterBetPlatformService,
    private authService: AuthService,
    private storageService: StorageService
  ) {}

  /**
   * TODO: Some of the features are not available in both providers. We should check if the feature is available in the current provider before calling the service
   * What this means is that we should revisit all of the features that should be supported by both providers and based on that decide can we publish changes in both providers/environments or only in one
   */

  //#region Routing
  public buildAllRoutes(): Routes {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return ALL_ROUTES_FOR_1X2TEAM;
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return ALL_ROUTES_FOR_BETPLATFORM;
    } else {
      return [];
    }
  }

  public buildSidemenuRoutes(): Partial<INavGroup>[] {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return SIDEMENU_NAV_GROUPS_FOR_1X2TEAM;
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return SIDEMENU_NAV_GROUPS_FOR_BETPLATFORM;
    } else {
      return [];
    }
  }

  private filterSidemenuNavGroups(groups: Partial<INavGroup>[]): Partial<INavGroup>[] {
    return groups?.filter((group: Partial<INavGroup>) => {
      const allGuardsPassed = null;
    });
  }
  //#endregion Routing

  //#region User | Authentication
  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.features?.storeCredentials === true && username && password) {
      const loginCredentials: ILoginCredentials = {
        username,
        password
      };

      await this.storageService.setLocalItem(BP_BACKOFFICE_STORAGE_KEYS.userCredentials, loginCredentials);
    }

    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.loginWithUsernameAndPassword(username, password);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.loginWithUsernameAndPassword(username, password);
    } else {
      return Promise.resolve(null);
    }
  }

  public async refreshToken(): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return Promise.resolve(null);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.refreshToken();
    } else {
      return Promise.resolve(null);
    }
  }

  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getUserProfile();
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getUserProfile();
    } else {
      return Promise.resolve(null);
    }
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.updateUserWithProfileData();
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.updateUserWithProfileData();
    } else {
      return Promise.resolve(null);
    }
  }
  //#endregion User | Authentication

  //#region Admin
  public async addPlayer(username: string, password: string): Promise<any> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return Promise.resolve(null); // TODO: We should check if the Add player feature is available in 1x2team
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.addPlayer(username, password);
    } else {
      return Promise.resolve({ results: [] });
    }
  }

  public async getPlayers(page: number): Promise<IApiPayload<Partial<IUserDetails>>> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getPlayers(page);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getPlayers();
    } else {
      return Promise.resolve({ results: [] });
    }
  }

  public async getPlayer(playerId: string): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getPlayer(playerId);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getPlayer(playerId);
    } else {
      return Promise.resolve(null);
    }
  }

  public async makePlayerDeposit(playerId: string, amount: number): Promise<Partial<ITransactionDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.makePlayerDeposit(playerId, amount);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.makePlayerDeposit(playerId, amount);
    } else {
      return Promise.resolve(null);
    }
  }

  public async makePlayerWithdrawal(playerId: string, amount: number): Promise<Partial<ITransactionDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.makePlayerWithdrawal(playerId, amount);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.makePlayerWithdrawal(playerId, amount);
    } else {
      return Promise.resolve(null);
    }
  }

  public async getPlayerTransactions(playerId: string, category: any, startDate: Date, endDate: Date): Promise<Partial<ITransactionDetails>[]> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getPlayerTransactions(playerId, category, startDate, endDate);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return Promise.resolve([]);
    } else {
      return Promise.resolve([]);
    }
  }

  public async blockPlayer(playerId: string): Promise<any> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return Promise.resolve(null); // TODO: We should check if the Block player feature is available in 1x2team
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.blockPlayer(playerId);
    } else {
      return Promise.resolve(null);
    }
  }

  public async unblockPlayer(playerId: string): Promise<any> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return Promise.resolve(null); // TODO: We should check if the Unblock player feature is available in 1x2team
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.unblockPlayer(playerId);
    } else {
      return Promise.resolve(null);
    }
  }

  public async awardJackpot(playerId: string, jackpotId: number): Promise<any> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return Promise.resolve(null); // TODO: We should check if the Award Jackpot  feature is available in 1x2team
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.awardJackpot(playerId, jackpotId);
    } else {
      return Promise.resolve(null);
    }
  }

  public async getJackpotsListForAgent(): Promise<IJackpotDetailsWhenAgent[]> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return Promise.resolve([]); // TODO: We should check if the Award Jackpot  feature is available in 1x2team
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getJackpotsListForAgent();
    } else {
      return Promise.resolve([]);
    }
  }

  /**
   * 
   * ERROR
   * {
    "status": "Unauthorized",
    "code": "",
    "error": "invalid token"
}

    * AFTER JACKPOT IS ALREADY AWARDED
{
    "status": "Internal Server Error.",
    "code": "",
    "error": "failed to get jackpot round: no rows in result set"
}
   */
  //#endregion Admin
}
