import { PROJECT_PREFIX } from 'bp-framework/dist/storage/storage.const';
import { IEnvConfigBackOffice } from 'bp-framework/dist/configuration/configuration.interface';
import { IEnvApiOn1x2Team } from 'bp-framework/dist/env-specific/1x2team/configuration/configuration.interface';

export const BASE_URLS_1X2TEAM = {
  office: 'https://office.1x2team.com',
  casino: 'https://office.1x2team.com',
  data: 'https://data.1x2team.com',
  feed: 'https://webfeed.zerosoft.bet'
};

const API_CONFIG_1X2TEAM: IEnvApiOn1x2Team = {
  provider: '1x2team',
  baseUrls: {
    office: BASE_URLS_1X2TEAM.office,
    casino: BASE_URLS_1X2TEAM.office,
    data: BASE_URLS_1X2TEAM.data,
    feed: BASE_URLS_1X2TEAM.feed
  },
  coreAuthEndpoints: {
    playerLogin: 'user/login',
    adminLogin: 'clients/login',
    getProfile: 'player/profile'
  }
};

export const PROJECT_CONFIG_1X2TEAM_PROD: IEnvConfigBackOffice<IEnvApiOn1x2Team> = {
  production: true,
  environmentName: 'prod',
  projectName: 'bp-backoffice-frontend',
  projectPrefix: PROJECT_PREFIX.backOfficeApp,
  httpInterceptors: {
    attachAuthTokenToTheseUrls: [BASE_URLS_1X2TEAM.office, BASE_URLS_1X2TEAM.data],
    doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS_1X2TEAM.office}/user/login`, `${BASE_URLS_1X2TEAM.office}/clients/login`],
    skip401HandlingForTheseUrls: []
  },
  api: API_CONFIG_1X2TEAM,
  features: {
    storeCredentials: true
  }
};

export const PROJECT_CONFIG_1X2TEAM_DEV: IEnvConfigBackOffice<IEnvApiOn1x2Team> = {
  production: false,
  environmentName: 'dev',
  projectName: 'bp-backoffice-frontend',
  projectPrefix: PROJECT_PREFIX.backOfficeApp,
  httpInterceptors: {
    attachAuthTokenToTheseUrls: [BASE_URLS_1X2TEAM.office, BASE_URLS_1X2TEAM.data],
    doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS_1X2TEAM.office}/user/login`, `${BASE_URLS_1X2TEAM.office}/clients/login`],
    skip401HandlingForTheseUrls: []
  },
  api: API_CONFIG_1X2TEAM,
  features: {
    storeCredentials: true
  }
};
