import { Injectable, inject } from '@angular/core';
import { AuthService, BpAdminApiService, BpCasinoApiService, BpCoreApiService } from 'bp-angular-library';
import { extractUserDetailsFromToken, mergeUserDetailsWithProfileData, transformPlayerDetailsToUserDetails } from 'bp-framework/dist/env-specific/betplatform/user/user.mappers';
import { IPlayer, ITokenPayload, IUser } from 'bp-framework/dist/env-specific/betplatform/user/user.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ICasinoGameDetails, ICasinoGameLaunchDetails } from 'bp-framework/dist/casino/casino.interface';
import { CasinoGame, CasinoLaunchDetails, CasinoPayload, IJackpotDetailsWhenAgent } from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';
import { mapCasinoGames } from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';
import { IBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.interface';
import { ITransactionDetails } from 'bp-framework/dist/transactions/transactions.interface';
import { PayloadTransaction } from 'bp-framework/dist/env-specific/betplatform/transactions/transactions.interface';
import { IApiPayload } from 'bp-framework/dist/api/api.interface';

@Injectable({
  providedIn: 'root'
})
export class AdapterBetPlatformService {
  private bpCoreApiService: BpCoreApiService = inject(BpCoreApiService);
  private bpCasinoApiService: BpCasinoApiService = inject(BpCasinoApiService);
  private bpAdminApiService: BpAdminApiService = inject(BpAdminApiService);
  private authService: AuthService = inject(AuthService);

  //#region User | Authentication
  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const loginPayload: ITokenPayload | null = await this.bpCoreApiService.authenticateWithUsernameAndPassword(username, password);

        if (!loginPayload?.access_token) {
          return reject(new Error('Failed to login! Check your credentials'));
        }

        const currentDetails: Partial<IUserDetails> | null = await this.authService.userAuthChanged(extractUserDetailsFromToken(loginPayload));

        resolve(currentDetails);
      } catch (error: unknown) {
        return reject(error);
      }
    });
  }

  public async refreshToken(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const refreshToken: string | undefined = await this.authService.user$.value?.auth?.refreshToken;

        if (!refreshToken) {
          return reject(new Error('Failed to refresh token! Refresh token is missing'));
        }

        const loginPayload: ITokenPayload | null = await this.bpCoreApiService.refreshToken(refreshToken);

        if (!loginPayload?.access_token) {
          return reject(new Error('Failed to refresh token! Something was wrong'));
        }

        const currentDetails: Partial<IUserDetails> | null = await this.authService.userAuthChanged(extractUserDetailsFromToken(loginPayload));

        resolve(currentDetails);
      } catch (error: unknown) {
        return reject(error);
      }
    });
  }

  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: IPlayer | null = await this.bpCoreApiService.getProfile();
        resolve(userProfile ? mergeUserDetailsWithProfileData({}, userProfile) : null);
      } catch (error) {
        return reject(new Error('Failed retrieve of user profile data'));
      }
    });
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: IPlayer | null = await this.bpCoreApiService.getProfile();
        const mapped: Partial<IUserDetails> | null = userProfile ? mergeUserDetailsWithProfileData({}, userProfile) : null;
        resolve(this.authService.userDetailsChanged(mapped));
      } catch (error) {
        return reject(new Error('Failed to update user profile data'));
      }
    });
  }
  //#endregion User | Authentication

  //#region Casino
  public async getAllCasinoGames(): Promise<IApiPayload<ICasinoGameDetails<any, any>>> {
    return new Promise<IApiPayload<ICasinoGameDetails<any, any>>>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoGame> | null = await this.bpCasinoApiService.getAllCasinoGames();

        const adaptedPayload: IApiPayload<ICasinoGameDetails<any, any>> = {
          count: response?.results?.length || 0,
          results: response?.results && Array.isArray(response?.results) ? mapCasinoGames(response.results) : []
        };
        resolve(adaptedPayload);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino games'));
      }
    });
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: CasinoLaunchDetails | null = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId);
        resolve({ url: response?.launchUrl, token: response?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error('Failed retrieve the game launch url'));
      }
    });
  }

  public async getSingleCasinoGameToPlay(gameId: number): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: any = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId);
        resolve(response);
      } catch (error) {
        return reject(new Error('Failed retrieve the single game play'));
      }
    });
  }
  //#endregion Casino

  //#region Admin
  public async addPlayer(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const response: Partial<IPlayer> | null = await this.bpAdminApiService.addPlayer(username, password);
        const mapped: Partial<IUserDetails> | null = response ? mergeUserDetailsWithProfileData({}, response) : null;
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed to add a new player'));
      }
    });
  }

  public async getPlayers(): Promise<IApiPayload<Partial<IUserDetails>>> {
    return new Promise<IApiPayload<Partial<IUserDetails>>>(async (resolve, reject) => {
      try {
        const response: IBpPayload<Partial<IUser>> | null = await this.bpAdminApiService.getPlayers();
        const mapped: Partial<IUserDetails>[] = response?.results && Array.isArray(response?.results) ? response.results.map((value: Partial<IUser>) => transformPlayerDetailsToUserDetails(value)).filter((userDetails): userDetails is Partial<IUserDetails> => userDetails !== null) : [];

        const adaptedPayload: IApiPayload<Partial<IUserDetails>> = {
          count: response?.results?.length || 0,
          results: mapped
        };

        resolve(adaptedPayload);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of player'));
      }
    });
  }

  public async getPlayer(playerId: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const response: Partial<IPlayer> | null = await this.bpAdminApiService.getPlayer(playerId);
        const mapped: Partial<IUserDetails> | null = response ? mergeUserDetailsWithProfileData({}, response) : null;
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of player'));
      }
    });
  }

  public async makePlayerDeposit(playerId: string, amount: number): Promise<Partial<ITransactionDetails> | null> {
    return new Promise<Partial<ITransactionDetails> | null>(async (resolve, reject) => {
      try {
        const response: Partial<PayloadTransaction> | null = await this.bpAdminApiService.makePlayerDeposit(playerId, amount);
        const mapped: Partial<ITransactionDetails> | null = response?.new_balance ? { newBalance: response.new_balance } : null;
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed to make a deposit on a player'));
      }
    });
  }

  public async makePlayerWithdrawal(playerId: string, amount: number): Promise<Partial<ITransactionDetails> | null> {
    return new Promise<Partial<ITransactionDetails> | null>(async (resolve, reject) => {
      try {
        const response: Partial<PayloadTransaction> | null = await this.bpAdminApiService.makePlayerWithdrawal(playerId, amount);
        const mapped: Partial<ITransactionDetails> | null = response?.new_balance ? { newBalance: response.new_balance } : null;
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed to make a withdrawal on a player'));
      }
    });
  }

  public async blockPlayer(playerId: string): Promise<any> {
    return this.bpAdminApiService.blockPlayer(playerId);
  }

  public async unblockPlayer(playerId: string): Promise<any> {
    return this.bpAdminApiService.unblockPlayer(playerId);
  }

  public async awardJackpot(playerId: string, jackpotId: number): Promise<any> {
    return this.bpCasinoApiService.awardJackpot(playerId, jackpotId);
  }

  public async getJackpotsListForAgent(): Promise<IJackpotDetailsWhenAgent[]> {
    return new Promise<IJackpotDetailsWhenAgent[]>(async (resolve, reject) => {
      try {
        const response: IBpPayload<IJackpotDetailsWhenAgent> | null = await this.bpAdminApiService.getJackpotsListForAgent();
        resolve(response?.results || []);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of jackpots for admin'));
      }
    });
  }
  //#region Admin
}
