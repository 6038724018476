import { TranslateService } from '@ngx-translate/core';

import { AuthService, StorageService } from 'bp-angular-library';

import { LANGUAGE_ALPHA2CODE } from 'bp-framework/dist/i18n/i18n.const';
import { EnvAdapterService } from '../env-specific/env-adapter.service';
import { Router, Routes } from '@angular/router';
import { BP_BACKOFFICE_STORAGE_KEYS } from 'src/app/shared/models/storage/storage.const';

export function appInitFactory(router: Router, storageService: StorageService, authService: AuthService, translateService: TranslateService, envAdapterService: EnvAdapterService): () => Promise<boolean> {
  return () =>
    new Promise(async resolve => {
      await storageService.initializeStorageService();
      await authService.handleAppInitialization();

      const lastLanguage: string = await storageService.getLocalItem(BP_BACKOFFICE_STORAGE_KEYS.langAndRegion);

      translateService.setDefaultLang(lastLanguage || LANGUAGE_ALPHA2CODE.en);
      translateService.use(lastLanguage || LANGUAGE_ALPHA2CODE.en);

      const appRoutes: Routes = envAdapterService.buildAllRoutes();
      router.resetConfig(appRoutes);

      resolve(true);
    });
}
