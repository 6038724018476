import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'bp-angular-library';
import { Observable } from 'rxjs';

// Returns a function which can act as a guard for a route
export const guardUntilAuthenticated = (): CanActivateFn => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return new Promise<boolean | UrlTree>(async (resolve, reject) => {
      // Step 1. Inject dependencies
      const router = inject(Router);
      const authService = inject(AuthService);

      const isTokenExpired: boolean = authService.isTokenExpired(); // Checks if the sitename from the URL matches the sitename inside the token

      if (isTokenExpired) {
        await authService.userAuthChanged(null);
        // TODO: Make paths as a constant so we can ensure that route paths are referenced by constant name and not hardcoded
        return resolve(router.parseUrl('login'));
      }

      return resolve(true);
    });
  };
};
