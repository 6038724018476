import { Component } from '@angular/core';
import { OUTLET_ANIMATION } from './shared/animations/animations';

@Component({
  selector: 'ion-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [OUTLET_ANIMATION]
})
export class AppComponent {
  title = 'bp-back-office';
  sidePaneState!: boolean;
}
