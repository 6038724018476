import { Routes } from '@angular/router';
import { INavGroup, ISidemenuNavGroup } from 'bp-framework/dist/common/common.interface';
import { guardUntilAuthenticated } from '../../guards/authentication.guard';

export const ALL_ROUTES_FOR_BETPLATFORM: Routes = [
  {
    path: 'login',
    loadComponent: () => import('../../.../../../pages/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: 'dashboard',
    loadComponent: () => import('../../../pages/dashboard/dashboard.component').then(m => m.DashboardComponent),
    canActivate: [guardUntilAuthenticated()]
  },
  //#region Users
  {
    path: 'users',
    children: [
      {
        path: 'players',
        loadChildren: () => import('../../../pages-users/players/players.routes').then(m => m.PLAYERS_ROUTES),
        canActivate: [guardUntilAuthenticated()]
      }
    ]
  },
  //#endregion Users
  //#region Feed
  {
    path: 'feed',
    children: [
      {
        path: 'markets-check',
        loadComponent: () => import('../../../pages-feed/markets-check/markets-check.component').then(m => m.FeedMarketsCheckComponent),
        canActivate: [guardUntilAuthenticated()]
      }
    ]
  },
  //#endregion Feed
  //#region Casino
  {
    path: 'casino',
    children: [
      {
        path: 'jackpot',
        loadChildren: () => import('../../../pages-casino/jackpot/jackpot.routes').then(m => m.JACKPOT_ROUTES),
        canActivate: [guardUntilAuthenticated()]
      }
    ]
  },
  //#endregion Casino
  //#region Surfpoint
  {
    path: 'surfpoint',
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('../../../pages-surfpoint/dashboard/dashboard.component').then(m => m.DashboardComponent),
        canActivate: [guardUntilAuthenticated()]
      },
      {
        path: 'terminals',
        loadComponent: () => import('../../../pages-surfpoint/terminals/terminals.component').then(m => m.TerminalsComponent),
        canActivate: [guardUntilAuthenticated()]
      },
      {
        path: 'reporting',
        loadChildren: () => import('../../../pages-surfpoint/reporting/reporting.routes').then(m => m.REPORTING_ROUTES),
        canActivate: [guardUntilAuthenticated()]
      }
    ]
  },
  //#endregion Surfpoint
  {
    path: 'theme',
    loadComponent: () => import('../../../pages/theme-testing/theme-testing.component').then(m => m.ThemeTestingComponent)
  },
  {
    path: 'not-found',
    loadComponent: () => import('../../../pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: '',
    redirectTo: '/users/players',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

export const SIDEMENU_NAV_GROUPS_FOR_BETPLATFORM: Partial<ISidemenuNavGroup<any>>[] = [
  // {
  //   path: 'dashboard',
  //   label: 'Dashboard',
  //   t9nKey: '',
  //   sortOrder: 0,
  //   icon: 'ph-house',
  //   children: []
  // },
  {
    path: 'users',
    label: 'Users',
    t9nKey: '',
    sortOrder: 1,
    icon: 'ph-user-list',
    expanded: true,
    guards: [guardUntilAuthenticated],
    children: [
      {
        path: 'players',
        label: 'Players',
        t9nKey: '',
        sortOrder: 0,
        icon: 'ph-user'
      }
      // {
      //   path: 'betshops',
      //   label: 'Betshops',
      //   t9nKey: '',
      //   sortOrder: 0,
      //   icon: 'ph-user'
      // },
      // {
      //   path: 'agents',
      //   label: 'Agents',
      //   t9nKey: '',
      //   sortOrder: 0,
      //   icon: 'ph-user'
      // },
      // {
      //   path: 'affiliates',
      //   label: 'Affiliates',
      //   t9nKey: '',
      //   sortOrder: 0,
      //   icon: 'ph-user'
      // },
      // {
      //   path: 'promoters',
      //   label: 'Promoters',
      //   t9nKey: '',
      //   sortOrder: 0,
      //   icon: 'ph-user'
      // },
      // {
      //   path: 'moderators',
      //   label: 'Moderators',
      //   t9nKey: '',
      //   sortOrder: 0,
      //   icon: 'ph-user'
      // },
      // {
      //   path: 'admins',
      //   label: 'Admins',
      //   t9nKey: '',
      //   sortOrder: 0,
      //   icon: 'ph-user'
      // }
    ]
  }
  // {
  //   path: 'betting',
  //   label: 'Betting',
  //   t9nKey: '',
  //   sortOrder: 1,
  //   icon: 'ph-soccer-ball',
  // expanded: true,
  //   children: [
  //     {
  //       path: 'tickets',
  //       label: 'Tickets',
  //       t9nKey: '',
  //       sortOrder: 0,
  //       icon: 'ph-user-list'
  //     },
  //     {
  //       path: 'risk',
  //       label: 'Risk Management',
  //       t9nKey: '',
  //       sortOrder: 1,
  //       icon: 'ph-list-checks'
  //     },
  //     {
  //       path: 'reporting',
  //       label: 'Reporting',
  //       t9nKey: '',
  //       sortOrder: 4,
  //       icon: 'ph-presentation-chart'
  //     },
  //     {
  //       path: 'configuration',
  //       label: 'Configuration',
  //       t9nKey: '',
  //       sortOrder: 5,
  //       icon: 'ph-gear'
  //     }
  //   ]
  // },
  // {
  //   path: 'casino',
  //   label: 'Casino',
  //   t9nKey: '',
  //   sortOrder: 2,
  //   icon: 'ph-club',
  // expanded: true,
  //   children: [
  //     {
  //       path: 'games',
  //       label: 'Games',
  //       t9nKey: '',
  //       sortOrder: 0,
  //       icon: 'ph-list-bullets'
  //     },
  //     {
  //       path: 'transactions',
  //       label: 'Transactions',
  //       t9nKey: '',
  //       sortOrder: 1,
  //       icon: 'ph-money'
  //     },
  //     {
  //       path: 'jackpot',
  //       label: 'Jackpot',
  //       t9nKey: '',
  //       sortOrder: 2,
  //       icon: 'ph-tip-jar'
  //     },
  //     {
  //       path: 'reporting',
  //       label: 'Reporting',
  //       t9nKey: '',
  //       sortOrder: 3,
  //       icon: 'ph-presentation-chart'
  //     },
  //     {
  //       path: 'configuration',
  //       label: 'Configuration',
  //       t9nKey: '',
  //       sortOrder: 4,
  //       icon: 'ph-gear'
  //     }
  //   ]
  // },
  // {
  //   path: 'surfpoint',
  //   label: 'SurfPoint',
  //   t9nKey: '',
  //   sortOrder: 3,
  //   icon: 'ph-monitor-play',
  // expanded: true,
  //   children: [
  //     {
  //       path: 'dashboard',
  //       label: 'Dashboard',
  //       t9nKey: '',
  //       sortOrder: 0,
  //       icon: 'ph-layout'
  //     },
  //     {
  //       path: 'games',
  //       label: 'Games',
  //       t9nKey: '',
  //       sortOrder: 0,
  //       icon: 'ph-list-bullets'
  //     },
  //     {
  //       path: 'terminals',
  //       label: 'Terminals',
  //       t9nKey: '',
  //       sortOrder: 0,
  //       icon: 'ph-user-list'
  //     },
  //     {
  //       path: 'transactions',
  //       label: 'Transactions',
  //       t9nKey: '',
  //       sortOrder: 1,
  //       icon: 'ph-money'
  //     },
  //     {
  //       path: 'jackpot',
  //       label: 'Jackpot',
  //       t9nKey: '',
  //       sortOrder: 2,
  //       icon: 'ph-tip-jar'
  //     },
  //     {
  //       path: 'reporting',
  //       label: 'Reporting',
  //       t9nKey: '',
  //       sortOrder: 3,
  //       icon: 'ph-presentation-chart'
  //     },
  //     {
  //       path: 'configuration',
  //       label: 'Configuration',
  //       t9nKey: '',
  //       sortOrder: 4,
  //       icon: 'ph-gear'
  //     }
  //   ]
  // }
  // {
  //   path: 'feed',
  //   label: 'Feed',
  //   t9nKey: '',
  //   sortOrder: 4,
  //   icon: 'ph-git-commit',
  // expanded: true,
  //   children: [
  //     {
  //       path: 'markets-check',
  //       label: 'Markets Check',
  //       t9nKey: '',
  //       sortOrder: 0,
  //       icon: 'ph-binoculars'
  //     }
  //     // {
  //     //   path: 'configuration',
  //     //   label: 'Configuration',
  //     //   t9nKey: '',
  //     //   sortOrder: 1,
  //     //   icon: 'ph-gear'
  //     // }
  //   ]
  // }
  // {
  //   path: 'users',
  //   label: 'Users',
  //   t9nKey: '',
  //   sortOrder: 4,
  //   icon: 'ph-users',
  // expanded: true,
  //   children: [
  //     {
  //       path: 'associates',
  //       label: 'Associates',
  //       t9nKey: '',
  //       sortOrder: 0,
  //       icon: 'ph-user-sound'
  //     },
  //     {
  //       path: 'players',
  //       label: 'Players',
  //       t9nKey: '',
  //       sortOrder: 1,
  //       icon: 'ph-user-list'
  //     },
  //     {
  //       path: 'configuration',
  //       label: 'Configuration',
  //       t9nKey: '',
  //       sortOrder: 2,
  //       icon: 'ph-gear'
  //     }
  //   ]
  // },
  // {
  //   path: 'finance',
  //   label: 'Finance',
  //   t9nKey: '',
  //   sortOrder: 5,
  //   icon: 'ph-currency-circle-dollar',
  // expanded: true,
  //   children: [
  //     {
  //       path: 'reporting',
  //       label: 'Reporting',
  //       t9nKey: '',
  //       sortOrder: 0,
  //       icon: 'ph-presentation-chart'
  //     },
  //     {
  //       path: 'billing',
  //       label: 'Billing',
  //       t9nKey: '',
  //       sortOrder: 1,
  //       icon: 'ph-invoice'
  //     },
  //     {
  //       path: 'configuration',
  //       label: 'Configuration',
  //       t9nKey: '',
  //       sortOrder: 2,
  //       icon: 'ph-gear'
  //     }
  //   ]
  // }
];
