import { Injectable, inject } from '@angular/core';
import { AdminApiService, AuthService, CasinoApiService, CoreApiService, SurfpointApiService } from 'bp-angular-library';
import { CasinoCategory, CasinoGame, CasinoGamePlay, CasinoPayload } from 'bp-framework/dist/env-specific/1x2team/casino/casino.interface';
import { ICasinoGameDetails, ICasinoGameLaunchDetails } from 'bp-framework/dist/casino/casino.interface';
import { ILoginPayload, IProfile } from 'bp-framework/dist/env-specific/1x2team/user/user.interface';
import { transformLoginDataToUserDetails, transformProfileDataToUserDetails } from 'bp-framework/dist/env-specific/1x2team/user/user.mappers';
import { mapCasinoCategories, mapCasinoGames, mapSurfpointCasinoGames } from 'bp-framework/dist/env-specific/1x2team/casino/casino.mappers';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ITransactionDetails } from 'bp-framework/dist/transactions/transactions.interface';
import { IPayloadResults, ICasinoGameDetails as SurfpointGameDetails } from 'bp-framework/dist/env-specific/1x2team/surfpoint/surfpoint.interface';
import { ICreditAddOrDepositResponse, ITransaction, TransactionApiCallType } from 'bp-framework/dist/env-specific/1x2team/transactions/transactions.interface';
import { convertTransactions } from 'bp-framework/dist/env-specific/1x2team/transactions/transactions.mappers';
import { Payload } from 'bp-framework/dist/env-specific/1x2team/common/common.interface';
import { IApiPayload } from 'bp-framework/dist/api/api.interface';

@Injectable({
  providedIn: 'root'
})
export class Adapter1x2teamService {
  private coreApiService: CoreApiService = inject(CoreApiService);
  private casinoApiService: CasinoApiService = inject(CasinoApiService);
  private adminApiService: AdminApiService = inject(AdminApiService);
  private authService: AuthService = inject(AuthService);
  private surfpointApiService: SurfpointApiService = inject(SurfpointApiService);

  //#region User | Authentication
  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const authResponse: Partial<ILoginPayload> | null = await this.coreApiService.authenticateAdminWithUsernameAndPassword(username, password);

        if (!authResponse?.token) {
          return reject(new Error('Failed to login! Check your credentials'));
        }

        await this.authService.userAuthChanged(transformLoginDataToUserDetails(authResponse, {}));

        const userProfile: Partial<IProfile> | null = await this.coreApiService.getProfile();

        if (!userProfile?.id) {
          return reject(new Error('Failed to retreive user details!'));
        }

        const user: Partial<IUserDetails> | null = await this.authService.userAuthChanged(transformLoginDataToUserDetails(authResponse, userProfile));
        resolve(user);
      } catch (error) {
        return reject(new Error('Failed to login. Please check your username or password or try again later!'));
      }
    });
  }

  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: Partial<IProfile> | null = await this.coreApiService.getProfile();
        resolve(userProfile ? transformProfileDataToUserDetails(userProfile) : null);
      } catch (error) {
        return reject(new Error('Failed retrieve of user profile data'));
      }
    });
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: Partial<IProfile> | null = await this.coreApiService.getProfile();
        const mapped: Partial<IUserDetails> | null = userProfile ? transformProfileDataToUserDetails(userProfile) : null;
        resolve(this.authService.userDetailsChanged(mapped));
      } catch (error) {
        return reject(new Error('Failed to update user profile data'));
      }
    });
  }
  //#endregion User | Authentication

  //#region Casino
  public async getCasinoCategories(): Promise<Partial<IListItem<number>>[]> {
    return new Promise<Partial<IListItem<number>>[]>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoCategory> | null = await this.casinoApiService.getCasinoCategories();
        const mapped: Partial<IListItem<number>>[] = response?.results && Array.isArray(response?.results) ? mapCasinoCategories(response.results) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino categories'));
      }
    });
  }

  public async getAllCasinoGames(): Promise<IApiPayload<ICasinoGameDetails<any, any>>> {
    return new Promise<IApiPayload<ICasinoGameDetails<any, any>>>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoGame> | null = await this.casinoApiService.getAllCasinoGames();
        const mapped: ICasinoGameDetails<any, any>[] = response?.results && Array.isArray(response?.results) ? mapCasinoGames(response.results) : [];
        resolve({ results: mapped });
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino games'));
      }
    });
  }

  public async getAllCasinoGamesForSurfpoint(): Promise<IApiPayload<ICasinoGameDetails<any, any>>> {
    return new Promise<IApiPayload<ICasinoGameDetails<any, any>>>(async (resolve, reject) => {
      try {
        const response: IPayloadResults<SurfpointGameDetails> | null = await this.surfpointApiService.getAvailableCasinoGames();
        const mapped: ICasinoGameDetails<any, any>[] = response?.results && Array.isArray(response?.results) ? mapSurfpointCasinoGames(response.results as any) : [];
        resolve({ results: mapped });
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino games'));
      }
    });
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: CasinoGamePlay | null = await this.casinoApiService.getCasinoGamePlayUrl(gameId);
        resolve({ url: response?.url, token: response?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error('Failed retrieve the game launch url'));
      }
    });
  }
  //#endregion Casino

  //#region Admin
  public async getPlayers(page: number): Promise<IApiPayload<Partial<IUserDetails>>> {
    return new Promise<IApiPayload<Partial<IUserDetails>>>(async (resolve, reject) => {
      try {
        const response: IPayloadResults<Partial<IProfile>> | null = await this.adminApiService.getPlayers(page);
        const mapped: Partial<IUserDetails>[] = response?.results && Array.isArray(response?.results) ? response.results?.map((value: Partial<IProfile>) => transformProfileDataToUserDetails(value)) : [];
        resolve({ results: mapped, count: response?.count || 0, totalPages: response?.totalPages || 0 });
      } catch (error) {
        return reject(new Error('Failed retrieve the list of player'));
      }
    });
  }

  public async getPlayer(playerId: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const response: Partial<IProfile> | null = await this.adminApiService.getPlayer(playerId);
        const mapped: Partial<IUserDetails> | null = response ? transformProfileDataToUserDetails(response) : null;
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the single player'));
      }
    });
  }

  public async getPlayerTransactions(playerId: string, categoryType: TransactionApiCallType, startDate: Date, endDate: Date): Promise<Partial<ITransactionDetails>[]> {
    return new Promise<Partial<ITransactionDetails>[]>(async (resolve, reject) => {
      try {
        const response: Payload<ITransaction> | null = await this.adminApiService.getPlayerTransactions(playerId, categoryType, startDate, endDate);
        const mapped: Partial<ITransactionDetails>[] = response?.results && Array.isArray(response?.results) ? convertTransactions(response.results) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed to retreive a list of transactions for a player'));
      }
    });
  }

  public async makePlayerDeposit(playerId: string, amount: number): Promise<Partial<ITransactionDetails> | null> {
    return new Promise<Partial<ITransactionDetails> | null>(async (resolve, reject) => {
      try {
        const response: Partial<ICreditAddOrDepositResponse> | null = await this.adminApiService.makePlayerDeposit(playerId, amount);
        const mapped: Partial<ITransactionDetails> | null = response?.balance ? { newBalance: response.balance } : null;
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed to make a deposit on a player'));
      }
    });
  }

  public async makePlayerWithdrawal(playerId: string, amount: number): Promise<Partial<ITransactionDetails> | null> {
    return new Promise<Partial<ITransactionDetails> | null>(async (resolve, reject) => {
      try {
        const response: Partial<ICreditAddOrDepositResponse> | null = await this.adminApiService.makePlayerWithdrawal(playerId, amount);
        const mapped: Partial<ITransactionDetails> | null = response?.balance ? { newBalance: response.balance } : null;
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed to make a withdrawal on a player'));
      }
    });
  }

  //#region Admin
}
